import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { configForms, ETSMenuItem } from 'ets-fe-ng-sdk';
import { Observable, of } from 'rxjs';
import { getConfigMainMenu } from 'projects/evolutics-client-ui/src/app/configs/menu-configs/top-menu.config';
import { ISearchResult } from 'projects/evolutics-admin-ui/src/app/Tasks/task-extras/task-setup.interface';
import { ISearchFormSchema } from '@Reusables/reusable-comps/find-item/find-item.model';
import { MenuItem } from '@Shared/models/IMenuItem';
import { TableCol } from '@Shared/models/index.model';
import { FaqAdminService } from './faq-admin.service';
import { FindItemComponent } from '../../../../../evolutics-shared-lib/src/lib/Reusables/reusable-comps/find-item/find-item.component';
import { NgIf } from '@angular/common';
import { LoaderComponent } from 'ets-fe-ng-sdk';

@Component({
    selector: 'app-dashboard-faq-admin',
    templateUrl: './dashboard-faq-admin.component.html',
    styleUrls: ['./dashboard-faq-admin.component.scss'],
    imports: [
        LoaderComponent,
        NgIf,
        FindItemComponent,
    ]
})
export class DashboardFaqAdminComponent implements OnInit {
  loading: boolean;
  schema: ISearchFormSchema[];
  displayedColumns: TableCol[];
  allMenus: MenuItem[] = [];
  selectedMenu: ETSMenuItem[] = [];
  otherLevelMenu: ETSMenuItem[] = [];
  form = new FormGroup({
    module: configForms.default(null, Validators.required),
    menu: configForms.default(null, Validators.required),
    screenPath: configForms.default(null, Validators.required),
  });
  constructor(
    private faqService: FaqAdminService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.getAllMenus()
  }

  get module() {
    return this.form.value.module;
  }

  get menu() {
    return this.form.value.menu;
  }

  get screenPath() {
    return this.form.value.screenPath;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    this.displayedColumns = [
      {
        f: 'code',
        t: 'Code',
        routeFormatter: (project) => {
          return './show?slug=' + project.code;
        },
      },
      { f: 'description', t: 'Question' },
      { f: 'menu', t: 'Menu' },
      { f: 'module', t: 'Module' },
      { f: 'screenPath', t: 'Screen Path' },
      { f: 'faq', t: 'FAQ' },
    ];
    this.schema = [
      {
        // Will become code when fixed by Ephraim
        field: 'code',
        label: 'Code',
        type: 'text',
        standalone: true,
        // asyncValidators: [this.cS.asyncValidateCompany],
        action: (form, cell) => {
          this.show(cell);
        },
      },
      {
        field: 'description',
        label: 'Description',
        type: 'text',
      },
      {
        field: 'module',
        label: 'Module',
        type: 'autocomplete',
        optionsInitFunc: (() => of(getConfigMainMenu()))(),
        labelType: 'il',
        valueField: 'id',
       onChangeFunc:(row:any, val:any, _this: FindItemComponent) =>{
          const r = val
          console.log(r)
          if(r  && this.allMenus?.length){
           let findMenu =   this.allMenus.find(({ id }) => id == r)
           let menus =   findMenu.hasSub ? findMenu.subs : [];
           _this.updateFormSchema('menu',{options: menus})
          }else{
            _this.updateFormSchema('menu',{options: []})
          }
        }
      },
      {
        field: 'menu',
        label: 'Menu',
        type: 'autocomplete',
        labelType: 'il',
        valueField: 'id',
        options: [],
         onChangeFunc:(row:any, val:any, _this: FindItemComponent) =>{
          const r = val
          if(r  && this.allMenus?.length){
           let findMenu =   this.allMenus.find(({ id }) => id == row.module)
           let selectedMenu =   findMenu.hasSub ? findMenu.subs : [];
            let menus = selectedMenu.find(({ id }) => id == r)

         let otherLevelMenu = [
           ...[].concat(...menus.subs)?.filter((e) => !e.hasSub),
           ...[]
           .concat(...menus.subs?.filter((e) => e.hasSub).map(({ subs }) => subs))
           .map((f) => {
          return {
            ...f,
            parent: menus.subs?.find(({ id }) => id == f.parentID)?.label,
          };
        }),
        ];

           _this.updateFormSchema('screenPath',{options: otherLevelMenu})
          }else{
            _this.updateFormSchema('screenPath',{options: []})
          }
        }
      },
      {
        field: 'screenPath',
        label: 'Screen Path',
        type: 'autocomplete',
        labelType: 'il',
        valueField: 'id',
      }
    ];
    this.loading = false;
  }

  /**
   *
   * @param query Search query
   * @returns Search observable for FindItemComponent
   */
  search = (query: any): Observable<ISearchResult> => {
    return this.faqService.searchFAQ(query);
  };
  getAllMenus() {
    this.allMenus = getConfigMainMenu();
  }

 getMenuById(v = this.module) {
    this.getListInSelectedMenu(this.allMenus.find(({ id }) => id == v));
  }

  getListInSelectedMenu(menu: any) {
    this.selectedMenu = menu.hasSub ? menu.subs : [];
  }

  selectMenu() {
    this.getOtherMenuList(this.selectedMenu.find(({ id }) => id == this.menu));
  }
  getOtherMenuList(menu: any) {
    this.otherLevelMenu = [
      ...[].concat(...menu.subs)?.filter((e) => !e.hasSub),
      ...[]
        .concat(...menu.subs?.filter((e) => e.hasSub).map(({ subs }) => subs))
        .map((f) => {
          return {
            ...f,
            parent: menu.subs?.find(({ id }) => id == f.parentID)?.label,
          };
        }),
    ];
  }

  show(cell) {
    this.router.navigate(['./show'], {
      relativeTo: this.route,
      queryParams: { slug: cell },
    });
  }

  pathFormatter = (c) => {
    let re = '';
    re += c.id + ' - ';
    if (c.parent) re += c.parent + ' - ';
    re += c.label;
    return re;
  };
}
