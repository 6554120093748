import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { configForms, EPageType, ETSMenuItem, ICodeDescription, ICodeTitle } from 'ets-fe-ng-sdk';
import { getConfigMainMenu } from 'projects/evolutics-client-ui/src/app/configs/menu-configs/top-menu.config';
import { UtilityService } from '@Services/utility.service';
import { MenuItem } from '@Shared/models/IMenuItem';

import { FaqAdminService } from '../dashboard-faq-admin/faq-admin.service';
import { DocCellInputComponent } from '../../shared/components/doc-cell-input/doc-cell-input.component';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { AutocompleteComponent } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgFor } from '@angular/common'; 
import { AdminCodesService } from 'projects/evolutics-admin-ui/src/app/Services/admin-codes.service'; 
import { LoaderComponent } from 'ets-fe-ng-sdk'; 

@Component({
    selector: 'app-dashboard-faq-create-new',
    templateUrl: './dashboard-faq-create-new.component.html',
    styleUrls: ['./dashboard-faq-create-new.component.scss'],
    imports: [
        LoaderComponent,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        BtnComponent,
        RouterLink,
        AutocompleteComponent,
        InputBasicComponent,
        NgFor,
        DocCellInputComponent,
    ]
})
export class DashboardFaqCreateNewComponent implements OnInit {
  closeResult = '';
  code: string = '';
  edit: boolean;
  selectedOption: 'IMAGE' | 'TEXT' | 'TABLE' = 'IMAGE';
  contentText = '';
  nbofSort: number = 1;
  allMenus: MenuItem[] = [];
  selectedMenu: ETSMenuItem[] = [];
  otherLevelMenu: ETSMenuItem[] = [];
  fileImg: any;
  loading: boolean = false;
  form: FormGroup;
  isEditing: boolean = false;
  submitting: boolean = false;
  activeIndex: number = 0;
  matForm = new FormGroup({
    matEditor: configForms.default(),
  });
  show: boolean;
  type: EPageType;
  id: any;
  index: any;
  systems: ICodeTitle[];
  constructor(
    private faqService: FaqAdminService,
    private util: UtilityService,
    private route: ActivatedRoute, 
    private router: Router,
    private fb: FormBuilder,
    private codeS: AdminCodesService, 
  ) {}

  get module() {
    return this.form.value.module;
  }

  get menu() {
    return this.form.value.menu;
  }

  get screenPath() {
    return this.form.value.screenPath;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.getAllMenus();
    this.codeS.getCodesByCodeSubGroup('SYSTEM_LIST').subscribe((res) => (this.systems = res));
    const queryParams = this.route.snapshot.queryParamMap;
    this.code = queryParams.get('slug');
    this.type = this.route.snapshot.data.type;
    this.code = queryParams.get('slug');
    this.edit = this.type == EPageType.editPage;
    this.show = this.type == EPageType.showPage;
    if (this.code) {
      this.loading = true;
      this.faqService.searchFAQ({ code: this.code }).subscribe(
        (v) => {
          let s = v.content?.[0];
          s = s || ({} as any);
          console.log(s);
          this.id = s.id;
          this.index = s.index;
          if (s?.texts?.length) this.controls.clear();
          this.form.patchValue(s);
          s?.texts?.forEach((text) => {
            const { contentType, content, config } = text as any;
            let contentData = {
              content: content?.content?.content || content?.content || content,
              config: content?.content?.config || content?.config || config,
            };
            if (contentType == 'TABLE' && !Array.isArray(content)) {
              contentData = {
                content: content?.content?.content || content?.content || content,
                config: content?.content?.config || content?.config || config,
              };
              const fields = contentData?.config?.map(({ f }) => f);
              const filteredConfig = contentData?.config?.filter(
                ({ f }, index) => !fields.includes(f, index + 1),
              );
              contentData.config = filteredConfig;
            }
            this.addContent(contentType, contentData);
          });
          this.getMenuById();
          this.selectMenu();
          if (this.show) this.form.disable();
          this.loading = false;
        },
        (e) => (this.loading = false),
      );
    }
  }
  /**
   * Create form and initial text field
   */
  initializeForm() {
    const queryParams = this.route.snapshot.queryParamMap.get('slug');
    this.form = new FormGroup({
      texts: new FormArray([]),
      //id: new FormControl(''),
      menu: new FormControl('', Validators.required),
      module: new FormControl('', Validators.required),
      screenPath: new FormControl('', Validators.required),
      system: new FormControl(''),
      description: new FormControl('', Validators.required),
      content: configForms.default(),
      contents: new FormArray([]),
      code: new FormControl(queryParams),
      index: new FormControl(''),
    });
    this.addContent();
    this.form.get('module').valueChanges.subscribe({
      next: (value) => {
        console.log(value);
        this.getMenuById(value);
      },
    });
    this.form.get('menu').valueChanges.subscribe({
      next: (value) => {
        console.log(value);
        this.selectMenu(value);
      },
    });
  }

  get controls() {
    return this.form.get('texts') as FormArray;
  }

  contentGroup({ config, content }: { config?: any; content?: any }) {
    return new FormGroup({
      config: new FormControl(config),
      content: new FormControl(content),
    });
  }

  /**
   * Add a control to the array of controls
   * @param type String enum of content type
   */
  addContent(contentType: 'TEXT' | 'TABLE' | 'UPLOAD' = 'TEXT', content = {}) {
    const dataItem = this.fb.group({
      contentType,
      content: this.contentGroup(content),
    });
    this.controls.push(dataItem);
  }

  getAllMenus() {
    this.allMenus = getConfigMainMenu();
  }
  getMenuById(v = this.module) {
    this.getListInSelectedMenu(this.allMenus.find(({ id }) => id == v));
  }
  getListInSelectedMenu(menu: any) {
    this.selectedMenu = menu.hasSub ? menu.subs : [];
  }

  selectMenu(v = this.menu) {
    this.getOtherMenuList(this.selectedMenu.find(({ id }) => id == v));
  }
  getOtherMenuList(menu: any) {
    this.otherLevelMenu = [
      ...[].concat(...menu.subs)?.filter((e) => !e.hasSub),
      ...[].concat(...menu.subs?.filter((e) => e.hasSub).map(({ subs }) => subs)).map((f) => {
        return {
          ...f,
          parent: menu.subs?.find(({ id }) => id == f.parentID)?.label,
        };
      }),
    ];
  }
  upload(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileImg = reader.result as string;
        console.log('image', this.fileImg);
      };
    }
  }

  getImage() {
    let as = document.getElementById('filebtn')?.click();
  }
  onEdit(row, index) {
    console.log('row', row);
    const { content, contentType } = row.value;
    // this.newRecordForm.patchValue(row);
    this.resetModal();
    this.openModal();
    this.selectedOption = contentType;
    this.contentText = contentType !== 'IMAGE' ? content : '';
    this.fileImg = contentType == 'IMAGE' ? content : '';
    this.activeIndex = index;
    this.isEditing = true;
  }
  resetModal() {
    this.isEditing = false;
    this.contentText = '';
    this.form.patchValue({ content: '' });
    this.selectedOption = 'IMAGE';
    this.fileImg = '';
    // this.newRecordForm.reset();
  }
  onSave() {
    let data = {
      contentType: this.selectedOption,
      content: this.selectedOption == 'IMAGE' ? this.fileImg : this.form.value.content,
    };

    if (this.isEditing) {
      console.log('Editing');
      this.textContent.at(this.activeIndex).patchValue(data);
    } else {
      console.log('Not Editing');
      this.addNewtTextContent(data);
    }
    this.closeModal();
    this.resetModal();
    console.log('content', this.contentText);
    console.log('selectedOption', this.selectedOption);
    console.log('file', this.fileImg);
    // console.log('Form', this.newRecordForm.value);
  }
  onSubmit() {
    if (this.form.invalid) return;
    this.submitting = true;
    console.log('Form', this.form.value);
    let payload = this.form.value;
    payload['texts'] = payload.texts?.map((txt) => {
      return {
        contentType: txt?.contentType,
        content: txt?.content?.content,
        config: txt?.content?.config,
        index: txt.index,
      };
    });
    this.faqService
      .createFaqs(payload)
      .subscribe(
        (res) => {
          console.log('Faq', res);
          this.util.info(`FAQ with code: ${res.code} has been saved successfully!.`, 1).then((s) => {
            // this.loading = false;
            // this.initializeForm();
            this.form.disable();
            this.router.navigate(['../show'], {
              relativeTo: this.route,
              queryParams: { slug: res.code },
            });
          });
        },
        () => {
          this.util.info('Unable to create FAQ!.', 0);
        },
      )
      .add(() => (this.submitting = false));
  }

  // get id() {
  //   return this.form.value.id;
  // }
  /*

   * Call the Update endpoint on the form

   */
  onUpdate() {
    if (this.form.invalid) return;
    this.submitting = true;
    this.form.get('code').setValue(this.code);
    console.log('Form', this.form.value);
    let payload = this.form.value;
    payload['faq'] = this.form.value.code;
    payload['texts'] = payload.texts?.map((txt, index) => {
      return {
        contentType: txt?.contentType,
        content: txt?.content?.content || txt?.content,
        config: txt?.content?.config || txt?.config,
        index: index,
      };
    });
    this.faqService
      .updateFaqs(this.id, payload)
      .subscribe(
        (res) => {
          console.log('Faq', res);
          this.util.info(`FAQ with code: ${res.code} has been updated successfully!.`, 1).then((s) => {
            this.form.disable();
          });
        },
        () => {
          this.util.info('Unable to update FAQ!.', 0);
        },
      )
      .add(() => (this.submitting = false));
  }

  get textContent() {
    return this.form.get('contents') as FormArray;
  }
  addNewtTextContent(val?, index: number = this.textContent?.length || 0) {
    const form = new FormGroup({
      content: new FormControl(''),
      contentType: new FormControl(''),
      id: new FormControl(''),
    });
    if (val) {
      form.patchValue(val);
    }
    this.textContent.insert(index, form);
  }
  removetextContent(i) {
    this.textContent.removeAt(i);
  }

  openModal() {
    let as = document.getElementById('modal-opener')?.click();
  }
  closeModal() {
    let as = document.getElementById('modal-close')?.click();
  }
  sortCounter() {
    return new Array(this.nbofSort);
  }

  sortInc() {
    this.nbofSort = this.nbofSort + 1;
  }

  pathFormatter = (c) => {
    let re = '';
    re += c.id + ' - ';
    if (c.parent) re += c.parent + ' - ';
    re += c.label;
    return re;
  };
}
